import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import HomeFilter from "../../customcomonents/HomeFilter";
import CareerIcon1 from "../../../images/careerIcon/1.svg";
import CareerIcon2 from "../../../images/careerIcon/2.svg";
import CareerIcon3 from "../../../images/careerIcon/3.svg";
import CareerIcon4 from "../../../images/careerIcon/4.svg";
import CareerIcon5 from "../../../images/careerIcon/5.svg";
import Logo from "../../../images/logo.png";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader";
import { API_URL } from "../../../../utils/API_URL";
import toast from "react-hot-toast";
import { Helper } from "../../../../utils/HelperFunction";
const CareerReport = () => {
  const componentRef = useRef();
  const location = useLocation();
  const state = location.state;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (!state.code) {
        return toast.error("No career code selected");
      }
      const url = `${API_URL.CAREER_REPORT}?soc_code=${state.code}`;
      const result = await Helper.GetData(url);
      if (result?.data?.status) {
        setData(result.data.data);
      } else {
        setData({});
        toast.error(result.response?.data?.message);
      }
    } catch (err) {
      setData({});
      console.log(err);
      toast.error("App Error");
    }
  };
  const downloadAsPDF = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const element = componentRef.current;
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add the logo
      const logoURL = Logo; // Replace with the logo's URL or base64 string
      const logoWidth = 40; // Adjust width as needed
      const logoHeight = 10; // Adjust height as needed
      const logoX = (pdfWidth - logoWidth) / 2; // Center horizontally
      const logoY = 5; // Position from the top edge

      // Add logo image
      pdf.addImage(logoURL, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Capture the element as a canvas
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
      });
      const imgData = canvas.toDataURL("image/png");

      // Get image dimensions
      const imgWidth = pdfWidth; // Match the PDF width
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = logoY + logoHeight + 5; // Position content below the logo

      // Calculate the maximum content height per page
    const maxContentHeight = pdfHeight - position; 

      // Add content to PDF page by page
      while (heightLeft > 0) {
        // const pageHeight = heightLeft > pdfHeight ? pdfHeight : heightLeft;
        const pageHeight = heightLeft > maxContentHeight ? maxContentHeight : heightLeft;
        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );

        // pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

        heightLeft -= pageHeight; // Reduce the height left by one page
        position -= pageHeight; // Move the content up for the next page

        if (heightLeft > 0) {
          pdf.addPage();
          pdf.addImage(logoURL, "PNG", logoX, logoY, logoWidth, logoHeight); 
        }
      }

      // Save the generated PDF
      pdf.save("Career_Report.pdf");

    } catch (err) {
      console.err(err);
    } finally {
      setLoading(false);
    }
  };


  console.log("report", data);
  return data ? (
    <React.Fragment>
      <section className="homePad bgColor minheight65vh">
        <Container>
          <Row>
            <Col lg={3}>
              <HomeFilter />
            </Col>
            <Col lg={9}>
              <div className="takeAssessment_div">
                <div className="Questionnaire_head d-sm-flex align-items-center justify-content-between gap-2">
                  <div className="flex-grow-1">
                    <h1>Career Report</h1>
                    <p>Here is your career report based on your result.</p>
                  </div>
                  <Button
                    onClick={downloadAsPDF}
                    className="flex-shrink-0"
                    disabled={loading}
                  >
                    Download Report
                  </Button>
                </div>
                <div ref={componentRef}>
                  <div className="carrerReport_card">
                    <div className="carrerReport_head">
                      <span className="Icon_img">
                        <Image src={CareerIcon1} alt="" />
                      </span>
                      <div className="carrerHead_cont">
                        <h4>{data?.overview?.title}</h4>
                      </div>
                    </div>
                    <Row className="g-4">
                      <Col md={6}>
                        <div className="careerReport_box">
                          <h4 className="careerReportBox_title">Also Called</h4>
                          {data?.overview?.also_called?.title?.map(
                            (itm, idx) => (
                              <p key={idx}>{itm}</p>
                            )
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="careerReport_box">
                          <h4 className="careerReportBox_title">
                            What They Do
                          </h4>
                          <p className="dot_remove">
                            {data?.overview?.what_they_do}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="careerReport_box">
                          <h4 className="careerReportBox_title">
                            On The Job Tasks
                          </h4>
                          {data?.overview?.on_the_job?.task?.map((itm, idx) => (
                            <p key={idx}> {itm}</p>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="carrerReport_card">
                    <div className="carrerReport_head">
                      <span className="Icon_img">
                        <Image src={CareerIcon2} alt="" />
                      </span>
                      <div className="carrerHead_cont">
                        <h4>Knowledge</h4>
                      </div>
                    </div>
                    <Row className="g-4">
                      {data?.knowledge?.group?.map((itm, idx) => (
                        <Col md={6} key={idx}>
                          <div className="careerReport_box">
                            <h4 className="careerReportBox_title">
                              {itm?.title?.name}
                            </h4>
                            {itm?.element?.map((el) => (
                              <p key={el.id}>{el?.name}</p>
                            ))}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="carrerReport_card">
                    <div className="carrerReport_head">
                      <span className="Icon_img">
                        <Image src={CareerIcon3} alt="" />
                      </span>
                      <div className="carrerHead_cont">
                        <h4>Skills</h4>
                      </div>
                    </div>
                    <Row className="g-4">
                      {data?.skills?.group?.map((itm) => (
                        <Col md={6} key={itm?.id}>
                          <div className="careerReport_box">
                            <h4 className="careerReportBox_title">
                              {itm?.title?.name}
                            </h4>
                            {itm?.element?.map((el) => (
                              <p key={el.id}>{el?.name}</p>
                            ))}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="carrerReport_card">
                    <div className="carrerReport_head">
                      <span className="Icon_img">
                        <Image src={CareerIcon4} alt="" />
                      </span>
                      <div className="carrerHead_cont">
                        <h4>Personality</h4>
                        {/* <p>Top Interest</p> */}
                      </div>
                    </div>
                    <Row className="g-4">
                      <Col md={6}>
                        <div className="careerReport_box">
                          <h4 className="careerReportBox_title">
                            Education needed
                          </h4>
                          {data?.personality?.education_usually_needed?.category?.map(
                            (itm, idx) => (
                              <p key={idx}>{itm}</p>
                            )
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="careerReport_box">
                          <h4 className="careerReportBox_title">
                            Apprenticeships
                          </h4>
                          {data?.personality?.apprenticeships?.title?.map(
                            (itm, idx) => (
                              <p key={idx}>{itm?.name}</p>
                            )
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="carrerReport_card">
                    <div className="carrerReport_head">
                      <span className="Icon_img">
                        <Image src={CareerIcon5} alt="" />
                      </span>
                      <div className="carrerHead_cont">
                        <h4>Education</h4>
                      </div>
                    </div>
                    <Row className="g-4">
                      <Col md={12}>
                        <div className="careerReport_box">
                          <div className="careerReport_head">
                            {<h5>{data?.education?.top_interest?.title}</h5>}
                            <h6>
                              {data?.education?.top_interest?.description}
                            </h6>
                          </div>
                          {data?.education?.work_styles?.element?.map((itm) => (
                            <p key={itm?.id}>{itm?.name}</p>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  ) : (
    <Loader />
  );
};

export default CareerReport;
