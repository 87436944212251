import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorBoundary from "../component/inc/ErrorBoundry";
import CompareCollege from "../component/pages/preferencesscreens/CompareCollege";
import CollegeDetails from "../component/pages/preferencesscreens/CollegeDetails";
import FollowedColleges from "../component/pages/preferencesscreens/FollowedColleges";
import CareerReport from "../component/pages/afterloginpages/TakeAssessment/CareerReport";

const Resources = lazy(() => import("../component/pages/Resources"));
const Pricing = lazy(() => import("../component/pages/Pricing"));
const Terms = lazy(() => import("../component/pages/Terms"));
const StudyScreen = lazy(() =>
  import("../component/pages/preferencesscreens/StudyScreen")
);
const CollegesScreen = lazy(() =>
  import("../component/pages/preferencesscreens/CollegesScreen")
);
const DiscoverScreen = lazy(() =>
  import("../component/pages/preferencesscreens/DiscoverScreen")
);
const CollegesTabScreen = lazy(() =>
  import("../component/pages/preferencesscreens/CollegesTabScreen")
);
const Home = lazy(() => import("../component/pages/afterloginpages/home/Home"));
const MyNetwork = lazy(() =>
  import("../component/pages/afterloginpages/MyNetwork")
);
const OfficerMyNetwork = lazy(() =>
  import("../component/pages/afterloginpages/admissionofficer/MyNetwork")
);
const Pages = lazy(() =>
  import("../component/pages/afterloginpages/admissionofficer/Pages")
);
const Followers = lazy(() =>
  import("../component/pages/afterloginpages/admissionofficer/Followers")
);
const Groups = lazy(() =>
  import("../component/pages/afterloginpages/group/Groups")
);
const GroupDetail = lazy(() =>
  import("../component/pages/afterloginpages/group/GroupDetail")
);
const Extracurriculars = lazy(() =>
  import("../component/pages/afterloginpages/passion/Extracurriculars")
);
const PassionProjectDetail = lazy(() =>
  import("../component/pages/afterloginpages/passion/PassionProjectDetail")
);
const PagesDetail = lazy(() =>
  import("../component/pages/afterloginpages/admissionofficer/PagesDetail")
);
// const StudentPages = lazy(() =>
//   import("../component/pages/afterloginpages/student/Pages")
// );
const Profile = lazy(() =>
  import("../component/pages/afterloginpages/Profile")
);
const Profile2 = lazy(() =>
  import("../component/pages/afterloginpages/Profile2")
);
const Search = lazy(() => import("../component/pages/afterloginpages/Search"));
const Notifications = lazy(() =>
  import("../component/pages/afterloginpages/Notifications")
);
const Questionnaire = lazy(() =>
  import("../component/pages/afterloginpages/TakeAssessment/Questionnaire")
);
const QuestionnaireResult = lazy(() =>
  import(
    "../component/pages/afterloginpages/TakeAssessment/QuestionnaireResult"
  )
);
const PrivacyPolicy = lazy(() => import("../component/pages/PrivacyPolicy"));
const FAQS = lazy(() => import("../component/pages/faqs"));
const TermsAndConditions = lazy(() =>
  import("../component/pages/TermsAndConditions")
);
// const AddAcollege = lazy(() =>
//   import("../component/pages/preferencesscreens/AddAcollege")
// );
const Otp = lazy(() => import("../component/pages/auth/Otp"));
const Blocked = lazy(() => import("../component/pages/auth/Blocked"));
const Chat = lazy(() => import("../component/pages/afterloginpages/Chat"));
const ChangePassword = lazy(() =>
  import("../component/pages/afterloginpages/ChangePassword")
);
const OtherProfile = lazy(() =>
  import("../component/pages/afterloginpages/OtherProfile")
);
const PostDetail = lazy(() =>
  import("../component/pages/afterloginpages/home/PostDetail")
);

const RouteIndex = () => {
  const { role } = useSelector((state) => state.User);
  return (
    // role?
    // <Suspense fallback={<Loader />}>
    <ErrorBoundary>
      <Routes>
        <Route path="/otp" element={<Otp />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/post" element={<PostDetail />} />
        <Route path="/no-auth" element={<Blocked />} />
        <Route path="/study" element={<StudyScreen />} />
        <Route path="/discover" element={<DiscoverScreen />} />
        <Route path="/collegestab" element={<CollegesTabScreen />} />
        <Route path="/followed-colleges" element={<FollowedColleges />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/college-screen" element={<CollegesScreen />} />
        <Route
          path="/my-network"
          element={
            role?.role === "student" ? <MyNetwork /> : <OfficerMyNetwork />
          }
        />
        <Route
          path="/page"
          element={role?.role === "student" ? <Blocked /> : <Pages />}
        />
        {/* <Route path="/StudentPages" element={<StudentPages />} /> */}
        <Route path="/Followers" element={<Followers />} />
        <Route
          path="/groups"
          element={role?.role === "student" ? <Groups /> : <Home />}
        />
        <Route
          path="/groups/detail"
          element={role?.role === "student" ? <GroupDetail /> : <Home />}
        />
        <Route path="/chat" element={<Chat />} />
        <Route
          path="/my-passion-project"
          element={role?.role === "student" ? <Extracurriculars /> : <Home />}
        />
        <Route
          path="/my-passion-project/detail"
          element={
            role?.role === "student" ? <PassionProjectDetail /> : <Home />
          }
        />
        <Route path="/page/details" element={<PagesDetail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile2" element={<Profile2 />} />
        <Route path="/search" element={<Search />} />
        {/* <Route path="/addacollege" element={<AddAcollege />} /> */}
        <Route path="/addacollege" element={<CompareCollege />} />
        <Route path="/college-detail" element={<CollegeDetails />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/assessment" element={<Questionnaire />} />
        <Route path="/assessment/result" element={<QuestionnaireResult />} />
        <Route path="/assessment/report" element={<CareerReport />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<FAQS />} />
        <Route path="/termsconditions" element={<TermsAndConditions />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/other-profile" element={<OtherProfile />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ErrorBoundary>
    // </Suspense>:<Loader />
  );
};

export default RouteIndex;
