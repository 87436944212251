import React from "react";
import { Spinner } from "react-bootstrap";

function Loader() {
  return (
    <div className="loaderdiv">
      <Spinner style={{color:"#7b76f1"}} animation="grow" />
    </div>
  );
}

export default Loader;
